/**
 * MinersFactory | navigation component
 */

import React from "react"
import { Link } from "gatsby"

const Navigation = () => (
    <nav className="navbar navbar-expand-lg fixed-top navbar-dark bg-custom">
        <Link to="/" className="navbar-brand">
            MinersFactory
        </Link>
        <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
                <Link
                    to="/"
                    className="nav-item nav-link"
                    activeClassName="active"
                >
                    Startseite <span className="sr-only">(current)</span>
                </Link>
                <Link
                    to="/news/"
                    className="nav-item nav-link"
                    activeClassName="active"
                >
                    News-Updates
                </Link>
                <Link
                    to="/faq/"
                    className="nav-item nav-link"
                    activeClassName="active"
                >
                    wichtige Fragen und Antworten
                </Link>
                <Link
                    to="/team/"
                    className="nav-item nav-link"
                    activeClassName="active"
                >
                    Server-Team
                </Link>
            </div>
        </div>
    </nav>
)

export default Navigation
