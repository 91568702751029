/**
 * MinersFactory | privacy page
 */

import React from "react"

import SubPage from "./../templates/subpage"
import SEO from "./../components/seo"

const PrivacyPage = () => (
    <SubPage>
        <SEO
            title="Datenschutzerklärung"
            meta={[
                {
                    name: `robots`,
                    content: `noindex`,
                },
            ]}
        />
        <div className="container">
            <section>
                <h1>Datenschutzerklärung</h1>
                <p>
                    Für einen reibungslosen und sicheren Betrieb dieser
                    Internetseite speichern wir auf dem Webserver, auf dem diese
                    Website betrieben wird, personenbezogene Daten wie die
                    anfragende IP-Adresse, diverse Browser-Daten wie der
                    Browser-Header, der Browser- / Betriebssystemversion und
                    möglicherweise weitere Daten enthält, sowie die angefragte
                    Unterseite und Datum / Uhrzeit der Anfrage.
                </p>
                <p>
                    Auf dieser Internetseite finden Sie außerdem externe
                    Einbindungen (sog. Widgets) der Fa. Twitter mit Sitz in
                    Irland. Weitere Informationen über die dortige Art und Weise
                    der Datenverarbeitung finden Sie in diesem{" "}
                    <a
                        href="https://help.twitter.com/de/twitter-for-websites-ads-info-and-privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Auskunftsdokument von Twitter
                    </a>
                    .
                </p>
            </section>
        </div>
    </SubPage>
)

export default PrivacyPage
